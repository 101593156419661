// ----------------------------------------------
// imports
// ----------------------------------------------
import $ from 'jquery';
import anime from 'animejs';
import AOS from 'aos';
import barba from '@barba/core';
import barbaPrefetch from '@barba/prefetch';
import Rellax from 'rellax';
import OpacityScroll from './components/_opacityScroll.js';

// ----------------------------------------------
// inits
// ----------------------------------------------
$(() => {

  // barba
  barba.use(barbaPrefetch);

  barba.init({
    transitions: [{
      once() {
        anime({
          targets: '.transition',
          translateY: '-100%',
          easing: 'easeOutQuart',
          duration: 600,
          delay: 400,
          complete() {
            $('.transition').removeClass('transition-in');
            $('.transition').css('transform', 'translateY(100%)');
          }
        });
      },
      enter() {
        anime({
          targets: '.transition',
          translateY: '-100%',
          easing: 'easeOutQuart',
          duration: 600,
          delay: 400,
          begin() {
            window.scrollTo(0, 0);

            $('.transition').removeClass('transition-out').addClass('transition-in');
            $('[data-barba]').removeClass('js-hamburger');

            $('body').removeClass('js-scrolling-down js-scrolling-up');
          },
          complete() {
            $('.transition').removeClass('transition-in');
            $('.transition').css('transform', 'translateY(100%)');
          }
        });
      },
      leave() {
        return new Promise(resolve => {
          anime({
            targets: '.transition',
            translateY: '-=100%',
            easing: 'easeInQuart',
            duration: 600,
            begin() {
              $('.transition').addClass('transition-out');
            },
            complete() {
              resolve();
            }
          });
        });
      }
    }],
    views: [{
      namespace: 'home',
      afterEnter() {
        const rellax = new Rellax('.rellax');

        OpacityScroll.init();
        miscCycle();

        if ($('.posts').length && $('.posts__next').length) {
          InfiniteScroll.init();
        }
      }
    }, {
      namespace: 'articles',
      afterEnter() {
        const rellax = new Rellax('.rellax');

        OpacityScroll.init();
      }
    }, {
      namespace: 'article',
      afterEnter() {
        const rellax = new Rellax('.rellax');

        OpacityScroll.init();
      }
    }]
  });

  function inits() {
    AOS.init({
      duration: 1000,
      easing: 'ease',
      once: true
    });

    miscAnchor();
    miscNavigation();
  }

  barba.hooks.afterOnce(() => {
    inits();
  });

  barba.hooks.after(() => {
    inits();
  });
});
